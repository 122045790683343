// Remove loading screen on load
const $ = require('../helpers/pQuery');

const html = $('html')[0];
const lastItem = $('.fade-up-7')[0];

function removeAnimatingClass(event) {
    if (event.propertyName === 'opacity') {
        html.classList.remove('html--to-animate');
    }
    lastItem.removeEventListener('animationend', removeAnimatingClass);
}

module.exports = function() {
    lastItem.addEventListener('transitionend', removeAnimatingClass);
}
