const $ = require("../helpers/pQuery");
const serialize = require("form-serialize");
const form = $("#contact-form")[0];
const errorLabels = $(".form__error");
const emailjs = require("@emailjs/browser");

emailjs.init("NzKfomTbO72t_hKjM");

/* function onErrors(errors) { */
/*     errorLabels.forEach((errorLabel) => { */
/*         const elAttr = errorLabel.getAttribute("for"); */
/*         if (errors.indexOf(elAttr) > -1) { */
/*             errorLabel.classList.remove("form__error--inactive"); */
/*         } else { */
/*             errorLabel.classList.add("form__error--inactive"); */
/*         } */
/*     }); */
/* } */

function setFeedback(status) {
    form.classList.add("form--hidden");
    $(`.form-result--${status}`)[0].classList.remove("form-result--hidden");
}

function onSubmit(event) {
    const formData = new FormData(form);
    const formValues = Object.fromEntries(formData);
    console.log("formValues", formValues);
    event.preventDefault();

    emailjs.send("portfolio", "portfolio_contact_form", formValues).then(
        function (response) {
            setFeedback(true);
            console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
            setFeedback(false);
            console.error("FAILED...", error);
        }
    );
}

module.exports = () => {
    form.addEventListener("submit", onSubmit);
};
