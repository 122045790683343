const SweetScroll = require('sweet-scroll');
const $ = require('../helpers/pQuery');
const header = require('./header');
const mobileMenu = require('./mobileMenu');
const CONFIG = require('../config/config');

const links = $('.jsNavLink');
const closeContact = $('.jsCloseContact');
const slideWrap = $('.slide-wrap')[0];
const html = $('html')[0];

function getViewportOffset(el) {
    const viewportOffset = el.getBoundingClientRect();
    return viewportOffset.top;
}

function onClick(event, link, dest) {

    const mobile = window.matchMedia(`(max-width: ${CONFIG.mobileBreakpoint}px)`).matches;
    const mobileNavClosed = $('.nav')[0].classList.contains('nav--mobile-hidden');
    const sweetScroll = new SweetScroll({
        offset: -header.getHeight(),
        duration: CONFIG.scrollSpeed
    });

    function scroll() {
        slideWrap.removeEventListener('transitionend', scroll);
        sweetScroll.to(dest);
    }

    // Close contact then scroll
    function closeContact() {
        if (html.classList.contains('contact-active')) {
            html.classList.remove('contact-active');
            slideWrap.addEventListener('transitionend', scroll);
        } else {
            scroll();
        }
    }

    event.preventDefault();

    // If link links to contact panel, open panel after scroll,
    // if the link is clicked whilst the contact panel is nearby
    // animate to it faster.
    if (link.classList.contains('jsOpensContact')) {
        const distanceFromTop = getViewportOffset(slideWrap);
        function getSpeed(distance) {
            return distance < 100 ? 250 : CONFIG.scrollSpeed
        }
        sweetScroll.update({
            duration: getSpeed(distanceFromTop),
            afterScroll: function() {
                // Contact nav link highlight handled in CSS
                // via this selector for ease
                html.classList.add('contact-active');
            }
        });
    }

    // If mobile and nav open, hide menu then close contact panel,
    // otherwise just close contact panel!
    if (mobile && !mobileNavClosed) {
        mobileMenu.closeMenu(closeContact);
    } else {
        closeContact();
    }

}

module.exports = function() {

    links.forEach(function(link) {
        const dest = link.hash;
        link.addEventListener('click', event => onClick(event, link, dest));
    });

    closeContact.forEach(link => {
        link.addEventListener('click', event => {
            event.preventDefault();
            html.classList.remove('contact-active');
        });
    });

}
