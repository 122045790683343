const SweetScroll = require('sweet-scroll');
const $ = require('../helpers/pQuery');

const nav = $('.nav')[0];
const links = $('.nav__item', nav);

function openMenu(event) {
    nav.classList.remove('nav--mobile-hidden');
    // Hack to wait until display: block; on container
    setTimeout(slideInLinks, 50);
}

function slideInLinks() {
    links.forEach(link => {
        link.classList.remove('nav__item--mobile-hidden');
    });
}

function closeMenu(callback) {
    var lastLink = links[links.length - 1];
    links.forEach(function(link) {
        link.classList.add('nav__item--mobile-hidden');
    });
    lastLink.addEventListener('transitionend', function onSlideOut() {
        nav.classList.add('nav--mobile-hidden');
        lastLink.removeEventListener('transitionend', onSlideOut);
        callback();
    });
}

function addListener() {
    $('#js-nav-toggle')[0].addEventListener('click', event => {
        event.preventDefault();
        openMenu(event);
    });
}

module.exports = {
    addListener,
    closeMenu
}
