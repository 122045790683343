const $ = require('../helpers/pQuery');
const CONFIG = require('../config/config');

const projects = $('.project');
const videos = $('video');

function play(video) {
    video.parentNode.classList.add('video-container--playing');
    video.play();
}

function pause(video) {
    video.parentNode.classList.remove('video-container--playing');
    video.pause();
}

function togglePlay(video) {
    video.paused ? play(video) : pause(video);
}

// Animate Projects On Entering Viewport
module.exports = function() {

    videos.forEach(video => {
        new Waypoint({
            element: video,
            handler: function() {
                const video = this.element;
                if (!video.paused) pause(video);
            },
            offset: '100%'
        });
        new Waypoint({
            element: video,
            handler: function() {
                const video = this.element;
                if (!video.paused) pause(video);
            },
            offset: '-50%'
        });
        video.addEventListener('click', event => {
            const clicked = video;
            videos.forEach(video => {
                if (video !== clicked && !video.paused) pause(video);
            });
            togglePlay(clicked);
        });
    });

};
