const $ = require('../helpers/pQuery');
const CONFIG = require('../config/config');

const projects = $('.project');
const about = $('.section--about')[0];

// Animate Projects On Entering Viewport
module.exports = function() {

    projects.forEach(elem => {
        new Waypoint({
            element: elem,
            handler: function() {
                this.element.classList.toggle('project--inactive');
            }, offset: CONFIG.waypointOffset
        });
    });

    new Waypoint({
        element: about,
        handler: function() {
            this.element.classList.toggle('section--about--inactive');
        }, offset: CONFIG.waypointOffset
    });

};
