const $ = require('../helpers/pQuery');
const header = require('./header');

const sections = $('.jsWaypointSection');
const links = $('.nav__item');
const headerHeight = header.getHeight();

// Hightlight Current Navigation Link
module.exports = function() {

    sections.forEach(section => {

        const waypoint = new Waypoint({
            element: section,
            handler: function(direction) {

                function getActiveSection() {
                    if (direction === 'up') {
                        return section.previousElementSibling;
                    }
                    return section;
                }

                // Reset all links
                links.forEach(link => link.classList.remove('nav__item--active'));

                // Highlight active link
                const linkActive = $('.nav__item[href="#' + getActiveSection().getAttribute('id') + '"]')[0];
                linkActive.classList.add('nav__item--active');

            }, offset: headerHeight + 1

        });
    });

}
