require('../../node_modules/waypoints/lib/noframework.waypoints.js');
require('whatwg-fetch');

const contactForm = require('./modules/contactForm');
const fading = require('./modules/fading');
const googleAnalytics = require('./modules/googleAnalytics');
const header = require('./modules/header');
const highlightNav = require('./modules/highlightNav');
const loadingScreen = require('./modules/loadingScreen');
const mobileMenu = require('./modules/mobileMenu');
const siteNavigator = require('./modules/siteNavigator');
const videos = require('./modules/videos');

// Let's go!
document.addEventListener('DOMContentLoaded', function () {
    header.onScroll();
    fading();
    highlightNav();
    siteNavigator();
    contactForm();
    videos();
    mobileMenu.addListener();
    loadingScreen();
}, false );

googleAnalytics();
