const $ = require('../helpers/pQuery');

const home = $('#home')[0];
const header = $('.header')[0];
let clone;

function onScroll() {
    window.onscroll = function() {
        const viewportOffset = home.getBoundingClientRect();
        const top = viewportOffset.top;
        if (top < 0) {
            header.classList.add('header--fixed');
        } else {
            header.classList.remove('header--fixed');
        }
    };
}

function setFakeHeader() {
    clone = header.cloneNode(true);
    document.body.appendChild(clone);
    clone.classList.add('header--fixed');
}

function getHeight() {
    return clone.offsetHeight;
}

setFakeHeader();

module.exports = {
    getHeight,
    onScroll
};
