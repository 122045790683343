// Remove loading screen on load
const $ = require('../helpers/pQuery');
const animateAfterLoad = require('./animateAfterLoad');

const loadingScreen = $('.loading-screen')[0];
const html = $('html')[0];

function fadeInHomepageItems() {
    // Add loaded class
    html.classList.add('html--loaded');
    // Remove element from dom
    loadingScreen.remove();
    // Animate in items
    animateAfterLoad();
}

module.exports = function() {

    loadingScreen.classList.add('loading-screen--inactive');
    loadingScreen.addEventListener('transitionend', fadeInHomepageItems);

}
